exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-[slug]-js": () => import("./../../../src/pages/blog/[slug].js" /* webpackChunkName: "component---src-pages-blog-[slug]-js" */),
  "component---src-pages-blog-search-js": () => import("./../../../src/pages/blog/search.js" /* webpackChunkName: "component---src-pages-blog-search-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-pages-[slug]-js": () => import("./../../../src/pages/pages/[slug].js" /* webpackChunkName: "component---src-pages-pages-[slug]-js" */),
  "component---src-pages-portfolio-[slug]-js": () => import("./../../../src/pages/portfolio/[slug].js" /* webpackChunkName: "component---src-pages-portfolio-[slug]-js" */),
  "component---src-pages-team-[slug]-js": () => import("./../../../src/pages/team/[slug].js" /* webpackChunkName: "component---src-pages-team-[slug]-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-company-js": () => import("./../../../src/templates/company.js" /* webpackChunkName: "component---src-templates-company-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-storyblok-entry-full-slug-js": () => import("./../../../src/templates/{storyblokEntry.full_slug}.js" /* webpackChunkName: "component---src-templates-storyblok-entry-full-slug-js" */),
  "component---src-templates-team-js": () => import("./../../../src/templates/team.js" /* webpackChunkName: "component---src-templates-team-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

